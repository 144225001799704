import { useState, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker, StandaloneSearchBox, InfoWindow } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NewPropertyModal from "./newproperty.modal.component";
import { MenuItem, Box, Select } from '@mui/material';
import HomePropertyView from "./home.propertyview.component";
import EditingRequestHomeModal from './editingrequest.home.modal.component';
import {propertyAddForm, propertiesformap, requestsforpropertyhomepage, propertiesSiblingsByRequest, searchParams, search, mapProvider, propertyRequestForm } from "../actions/admin";
import "leaflet/dist/leaflet.css";
import DetailRequestModal from './detailrequest.modal.component';
import Info from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import Edit from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const mapContainerStyle = {
  width: 'auto',
  height: '90vh',
}
const DEFAULT_ZOOM = 7;
const DEFAULT_CENTER = { lat: 41.90, lng: 12.49 };

const API_KEY = sessionStorage.getItem("googleApiKey")

const markerRedIcon = {
  iconUrl: require('../map_images/marker-red-icon.png')
};

const markerBlueIcon = {
  iconUrl: require('../map_images/marker-blue-icon.png')
};

const markerGreenIcon = {
  iconUrl: require('../map_images/marker-green-icon.png')
};

const markerGreyIcon = {
  iconUrl: require('../map_images/marker-gray-icon.png')
};
const markerBlackIcon = {
  iconUrl: require('../map_images/marker-black-icon.png')
};
const markerSoldIcon = {
  iconUrl: require('../map_images/marker-sold-icon.png')
};
const markerNeverGiveUpIcon = {
  iconUrl: require('../map_images/marker-nevergu-icon.png')
};


const markerMapId = new Map([
  ['24d5bb0c-320d-11ee-be56-0242ac120002', markerBlueIcon], // STANDBY
  ['428e524e-320d-11ee-be56-0242ac120002', markerGreenIcon], // IN
  ['4cedf08c-320d-11ee-be56-0242ac120002', markerRedIcon], // OFF
  ['7262c8a1-31d5-4c46-aecf-6c29c301a884', markerBlackIcon], // VACANT
  ['b495110b-0b9b-4bbd-b383-ca9fed0a4f19', markerGreyIcon], // NOT IN SALE
  ['c8b5b498-f41c-4826-8415-fc8d55fd2fd2', markerSoldIcon], // SOLD
  ['a2c4b498-f41c-4826-8415-ad6b55fd2fa1', markerNeverGiveUpIcon] // NEVER GIVE UP

]);

const libraries = ["places"];


const Home = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries
  });

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [providerMap, setProviderMap] = useState(null);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showPropertyDetailPopup, setShowPropertyDetailPopup] = useState(false);
  const [properties, setProperties] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isResetFilterShown, setIsResetFilterShown] = useState(false);
  const [isTopFilterChecked, setIsTopFilterChecked] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [file, setFile] = useState();
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const [showModalEditing, setShowModalEditing] = useState(false);
  const [requestList, setRequestList] = useState();


  const [apiDataNewProperty, setApiDataNewProperty] = useState({
    classificationList: [],
    tagList: [],
    countryList: [],
    regionList: [],
    cityList: [],
    statusList: [],
    propertyTypeList: [],
    agentList: [],
    ownerList: [],
    formErrors: { price: "" }
  });
  const [formDataNewProperty, setFormDataNewProperty] = useState({
    id: "",
    idAgent: "",
    agenteUsername: "",
    name: "",
    price: "",
    mq: "",
    address: "",
    city: "",
    status: "",
    classification: "",
    propertyType: "",
    propertyTypeName: "",
    description: "",
    rooms: "",
    mqLand: "",
    swimmingPool: false,
    driveLink: "",
    websiteLink: "",
    latitude: "",
    longitude: "",
    country: "",
    region: "",
    tagSelected: [],
    ownerObj: { firstname: "", lastname: "", email: "", cell: "", fiscalCode: "" },
    owners: [],
    formErrors: { price: "", latitude: "" }
  });

  const [apiData, setApiData] = useState({
    classificationList: [],
    tagList: [],
    statusList: [],
    propertyTypeList: [],
    agentList: []
  });
  const [formData, setFormData] = useState({
    classification: "",
    propertyType: "",
    status: "",
    tagIdList: [],
    latitude: "",
    longitude: "",
    radius: "50",
    mqFrom: "",
    mqTo: "",
    mqLandFrom: "",
    mqLandTo: "",
    priceFrom: "",
    priceTo: "",
    roomsFrom: "",
    roomsTo: "",
    name: "",
    agentIdList: []
  });

  const realoadList = (data) => {
    setRequests(data);
  };

  const toggleTopFilterChecked = () => {
    setIsTopFilterChecked(!isTopFilterChecked);
  };

  const toggleModalEditing = (id) => {
    if (id != null && typeof id == 'string') {
      dispatch(propertyRequestForm(id))
        .then((data) => {
          setFormData(data.request);
          setApiData({
            ...apiData,
            tagList: data.tagList,
            agentList: data.userList,
            regionList: data.regionList,
            propertyList: data.propertyList

          });
        })    
        .catch(() => {
          console.log("Error");
        });
    }
    setShowModalEditing(!showModalEditing)
  };
 const toggleModalDetail = (id) => {
    if (id != null && typeof id == 'string') {
        dispatch(propertyRequestForm(id))
            .then((data) => {
                setRequestDetails(data.request);
            })
            .catch(() => {
                console.log("Error");
            });
    } else {
        setRequestDetails({});
    }
    setShowModalDetail(!showModalDetail)
};

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  const reloadData = () => {
    dispatch(propertiesformap())
      .then((data) => {
        setProperties(data);
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const toggleModalNew = () => {
    clearCacheData()

    setFormDataNewProperty({
      formErrors: { price: "" }
    });
    setFile();
    setShowModalNew(!showModalNew)
  };

  const resetFormData = () => {
    setFormData({
      classification: "",
      propertyType: "",
      status: "",
      tagIdList: [],
      latitude: "",
      longitude: "",
      radius: "50",
      mqFrom: "",
      mqTo: "",
      mqLandFrom: "",
      mqLandTo: "",
      priceFrom: "",
      priceTo: "",
      roomsFrom: "",
      roomsTo: "",
      name: "",
      agentIdList: []
    });
  }

  useEffect(() => {
    dispatch(mapProvider())
      .then((data) => {
        setProviderMap(data);
      })
      .catch(() => {
        console.log("Error");
      });
    dispatch(propertiesformap())
      .then((data) => {
        setProperties(data);
      })
      .catch(() => {
        console.log("Error");
      });
    dispatch(requestsforpropertyhomepage())
      .then((data) => {
        setRequests(data);
      })
      .catch(() => {
        console.log("Error");
      });
    dispatch(searchParams())
      .then((data) => {
        setApiData(data);
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  const toggleModalSearch = () => {
    setShowModalSearch(!showModalSearch);
  };

  const handleChangeSearch = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeSearchTags = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      tagIdList: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleChangeAgentAutoComplete = (event, val) => {
    setFormData({
        ...formData,
        agentIdList: val.map(v => v.id)
    });
};

  const handleNewSearchSubmit = (event) => {
    let newFormData = {};
    let buttonClicked = event.nativeEvent.submitter.name;

    if (buttonClicked === "reset") {
      resetFormData();
    } else {
      newFormData = formData;
    }

    dispatch(search(newFormData))
      .then((data) => {
        setProperties(data);
      })
      .catch(() => {
        console.log("Error");
      });

    setShowModalSearch(false);
    //map.setView(DEFAULT_CENTER, DEFAULT_ZOOM);
    event.preventDefault();
  };

  function Markers({ data }) {
    return (
      data.length > 0 &&
      data.map((property) => {
        return (
          <Marker position={{ lat: property.latitude, lng: property.longitude }} icon={markerMapId.get(property.classificationObj.id).iconUrl} key={property.id} onClick={() => handleActiveMarker(property.id)}    >
            {activeMarker === property.id ? (
              <InfoWindow position={{ lat: property.latitude, lng: property.longitude }} onCloseClick={() => setActiveMarker(null)}>
                <HomePropertyView showPropertyDetailPopup={true} setShowPropertyDetailPopup={setShowPropertyDetailPopup} propertyId={property.id} setProperties={setProperties} />
              </InfoWindow>
            ) : null}
          </Marker>
        );
      })
    );
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '54px',
    left: '10px',
  }

  const searchBoxPlace = () => {
    const places = searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    });

    map.fitBounds(bounds);
  }

  const onSBLoad = ref => {
    setSearchBox(ref);
  };


  let infoWindow = null;
  function openW(event) {
    if (infoWindow) {
      infoWindow.close();
    }
    infoWindow = new window.google.maps.InfoWindow({
      position: { lat: event.latLng.lat(), lng: event.latLng.lng() }
    });
    setFormDataNewProperty({
      ...formDataNewProperty,
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng()
    });
    var content =
      '<div id="content">' +
      event.latLng.lat() + ", " + event.latLng.lng() +
      "</div>" +
      '<div id="add">' +
      "<button class='button' id='polyButton'>+</button>" +
      "</div>";

    window.google.maps.event.addListener(infoWindow, "domready", function () {

      document.getElementById("polyButton").onclick = newProp

    });
    infoWindow.setContent(content);
    infoWindow.open(map);

  }

  function newProp() {
    dispatch(propertyAddForm())
      .then((data) => {
        setApiDataNewProperty(data);
      })
      .catch(() => {
        console.log("Error");
      });
    setShowModalNew(true);
    infoWindow.close();
  };
  const onMapLoad = ref => {
    setMap(ref);
    infoWindow = new window.google.maps.InfoWindow({});
  }

  if (loadError) return "Error loading Maps";
  if (!isLoaded) return "Loading Maps";

  const checkColor = (description) => {
    switch (description) {
      case true:
        return "#9B8C64";
      case false:
        return "white";
      default:
    }};

  const renderRequests = () => {
    
    if(requests) {
      let requestFiltered = requests;
      if(isTopFilterChecked) {
        requestFiltered = requests.filter(r => {
          return r.topRequest;
        });
      }

      return (
        requestFiltered.map((request, index) => (
        <Row className='mt-3'>
          <Col>
            <Card style={{backgroundColor: checkColor(request.topRequest)}}>
              <CardHeader
                title={request.createDate}
                subheader={request.clientName}
              />
              <CardContent>
                <Typography component="div" >
                  <b>Rif: </b> {request.property.name}
                </Typography>
                <Typography component="div">
                  <b>Agente: </b>  {request.agentName}
                </Typography>
                <Typography component="div">
                  <b>Range prezzo (€):  </b>{request.minPrice} - {request.maxPrice}
                </Typography>
              </CardContent>
              <CardActions>
              <IconButton size="small"  onClick={(e) => {
                  dispatch(propertiesSiblingsByRequest(request.id))
                    .then((data) => {
                      setProperties(data);
                    })
                    .catch(() => {
                      console.log("Error");
                    });
                  setIsResetFilterShown(true);
                }}>
                          <SearchIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => { if (request.editable) { toggleModalEditing(request.id) } }}>
                          <Edit />
                      </IconButton>
                <IconButton size="small"  onClick={() => toggleModalDetail(request.id)}>
                          <Info />
                      </IconButton>
              </CardActions>
            </Card>
          </Col>
        </Row>
        ))
      );
    }
    
  }

  return (
    <div>
      <div>
        <Button size="small" onClick={toggleModalSearch}>Cerca</Button>
      </div>
      <Container fluid>
        <Row xs="1" sm="1" md="2" lg="2" xl="2">
          <Col sm="12" md="10" lg="10" xl="10">
            {isLoaded && <GoogleMap zoom={DEFAULT_ZOOM} center={DEFAULT_CENTER} mapContainerStyle={mapContainerStyle} onLoad={onMapLoad} onClick={(e) => { setActiveMarker(null); openW(e) }} >
              <StandaloneSearchBox onPlacesChanged={searchBoxPlace} onLoad={onSBLoad} >
                <input
                  type='text'
                  placeholder='Cerca su google maps'
                  style={inputStyle}
                />
              </StandaloneSearchBox>
              <Markers data={properties} />
            </GoogleMap>
            }
          </Col>
          <Col sm="12" md="2" lg="2" xl="2">
            <Box style={{ maxHeight: '90vh', overflow: 'auto' }}>
              <Container fluid>
                
                <Button size="small" onClick={(e) => {
                    toggleTopFilterChecked();
                  }}>Top</Button>
                  {isResetFilterShown && (
                  <Button size="small" onClick={(e) => {
                    dispatch(propertiesformap())
                      .then((data) => {
                        setProperties(data);
                      })
                      .catch(() => {
                        console.log("Error");
                      });
                    setIsResetFilterShown(false);
                  }}>Reset</Button>
                )}
                {renderRequests()}
              </Container>
            </Box>
          </Col>
        </Row>
      </Container>

      <Modal id="modalSearch" className="modal-dialog-centered modal-xl" isOpen={showModalSearch} toggle={toggleModalSearch} >
        <ModalHeader toggle={toggleModalSearch}>Cerca</ModalHeader>
        <Form onSubmit={handleNewSearchSubmit}>
          <ModalBody>
            <Container>
              <Row xs="1" sm="1" md="2" lg="4" xl="4">
                <Col>
                  <FormGroup>
                    <Label for="classification">
                      Disponibilità
                    </Label>
                    <Select
                      type="select"
                      name="classification"
                      id="classification"
                      sx={{ minWidth: 230 }}
                      value={(formData.classification) || ""}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    >
                      <MenuItem
                        key=""
                        value=""
                      ></MenuItem>
                      {apiData.classificationList && apiData.classificationList.map(t => (
                        <MenuItem
                          key={t.description}
                          value={t.id}
                        >
                          {t.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col >
                  <FormGroup>
                    <Label for="propertyType">
                      Tipologia
                    </Label>
                    <Select
                      type="select"
                      name="propertyType"
                      id="propertyType"
                      sx={{ minWidth: 230 }}
                      value={formData.propertyType || ''}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    >
                      <MenuItem
                        key=""
                        value=""
                      ></MenuItem>
                      {apiData.propertyTypeList && apiData.propertyTypeList.map(t => (
                        <MenuItem
                          key={t.name}
                          value={t.id}
                        >
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col >
                  <FormGroup>
                    <Label for="status">
                      Stato
                    </Label>
                    <Select
                      type="select"
                      name="status"
                      id="status"
                      sx={{ minWidth: 230 }}
                      value={formData.status || ""}
                      onChange={handleChangeSearch}
                    >
                      <MenuItem
                        key=""
                        value=""
                      ></MenuItem>
                      {apiData.statusList && apiData.statusList.map(t => (
                        <MenuItem
                          key={t.description}
                          value={t.id}
                        >
                          {t.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col >
                  <FormGroup>
                    <Label for="tags">
                      Tags
                    </Label>
                    <Select
                      type="select"
                      name="tags"
                      id="tags"
                      multiple
                      sx={{ minWidth: 230 }}
                      value={formData.tagIdList || []}
                      onChange={handleChangeSearchTags}
                    >
                      <MenuItem
                        key=""
                        value=""
                      ></MenuItem>
                      {apiData.tagList && apiData.tagList.map(t => (
                        <MenuItem
                          key={t.tag}
                          value={t.id}
                        >
                          {t.tag}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="latitude">
                      Latitudine
                    </Label>
                    <Input
                      type="number"
                      name="latitude"
                      id="latitude"
                      value={formData.latitude}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="longitude">
                      Longitudine
                    </Label>
                    <Input
                      type="number"
                      name="longitude"
                      id="longitude"
                      value={formData.longitude}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="radius">
                      Raggio KM
                    </Label>
                    <Input
                      type="number"
                      name="radius"
                      id="radius"
                      value={formData.radius}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="mqFrom">
                      Mq - minimo
                    </Label>
                    <Input
                      type="number"
                      name="mqFrom"
                      id="mqFrom"
                      value={formData.mqFrom}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="mqTo">
                      Mq - massimo
                    </Label>
                    <Input
                      type="number"
                      name="mqTo"
                      id="mqTo"
                      value={formData.mqTo}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="mqLandFrom">
                      Mq terreno - minimo
                    </Label>
                    <Input
                      type="number"
                      name="mqLandFrom"
                      id="mqLandFrom"
                      value={formData.mqLandFrom}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="mqLandTo">
                      Mq terreno - massimo
                    </Label>
                    <Input
                      type="number"
                      name="mqLandTo"
                      id="mqLandTo"
                      value={formData.mqLandTo}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="priceFrom">
                      Prezzo - minimo
                    </Label>
                    <Input
                      type="number"
                      name="priceFrom"
                      id="priceFrom"
                      value={formData.priceFrom}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="priceTo">
                      Prezzo - massimo
                    </Label>
                    <Input
                      type="number"
                      name="priceTo"
                      id="priceTo"
                      value={formData.priceTo}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="roomsFrom">
                      Camere - minimo
                    </Label>
                    <Input
                      type="number"
                      name="roomsFrom"
                      id="roomsFrom"
                      value={formData.roomsFrom}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="roomsTo">
                      Camere - massimo
                    </Label>
                    <Input
                      type="number"
                      name="roomsTo"
                      id="roomsTo"
                      value={formData.roomsTo}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="name">
                      Nome Immobile
                    </Label>
                    <Input
                      type="string"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                      <Label for="agentIdList">
                          Agenti
                      </Label>
                      <Stack gap={1} direction="row" flexWrap="wrap">
                          <Autocomplete
                              onChange={handleChangeAgentAutoComplete}
                              multiple
                              sx={{ minWidth: 230 }}
                              name="agentIdList"
                              id="agentIdList"

                              options={apiData.agentList}
                              getOptionLabel={(option) => {
                                  if (typeof option === 'string') {
                                      return option;
                                  }
                                  if (option.inputValue) {
                                      return option.inputValue;
                                  }
                                  return option.firstname + " " + option.lastname;
                              }}

                              isOptionEqualToValue={(option, value) => option.id === value.id}

                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      variant="standard"
                                      label=""
                                      placeholder=""
                                  />
                              )}
                              value={formData.agentIdList && apiData.agentList && apiData.agentList.filter(s => { return formData.agentIdList.indexOf(s.id) >= 0 }) || []}
                          />
                      </Stack>
                  </FormGroup>
              </Col>

              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-primary custom-btn" name="search" type="submit">
              Cerca
            </Button>{' '}
            <Button className="btn btn-primary custom-btn" name="reset" type="submit">
              Reset
            </Button>{' '}
            <Button className="btn btn-primary custom-btn" onClick={toggleModalSearch}>
              Chiudi
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <NewPropertyModal showModalNew={showModalNew} toggleModalNew={toggleModalNew} setFile={setFile} file={file} setApiData={setApiDataNewProperty} apiData={apiDataNewProperty} setFormData={setFormDataNewProperty} formData={formDataNewProperty} reloadData={reloadData} />
      <DetailRequestModal showModalDetail={showModalDetail} toggleModalDetail={toggleModalDetail} requestDetails={requestDetails} />
      <EditingRequestHomeModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} formData={formData} setFormData={setFormData} apiData={apiData} setApiData={setApiData} realoadList={realoadList} />

    </div>
  );
}

export default Home;